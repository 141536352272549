import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix, getAccessLevel } from "../utils";

export default class Contact extends React.Component {
  render() {
    // var origin = "";
    // if (
    //   typeof window !== "undefined" &&
    //   window.localStorage &&
    //   window.localStorage.getItem("name")
    // ) {
    //   origin = window.localStorage.getItem("name");
    // }

    let origin = getAccessLevel("name");

    return (
      <Layout {...this.props}>
        <article className="post page post-full">
          <header className="post-header">
            <h1 className="post-title underline">
              {_.get(this.props, "pageContext.frontmatter.title")}
            </h1>
          </header>
          {_.get(this.props, "pageContext.frontmatter.subtitle") && (
            <div className="post-subtitle">
              {htmlToReact(
                _.get(this.props, "pageContext.frontmatter.subtitle")
              )}
            </div>
          )}
          {_.get(this.props, "pageContext.frontmatter.img_path") && (
            <div className="post-thumbnail">
              <img
                src={safePrefix(
                  _.get(this.props, "pageContext.frontmatter.img_path").replace(
                    /\.png|\.jpg$/,
                    ".webp"
                  )
                )}
                alt={_.get(this.props, "pageContext.frontmatter.title")}
              />
            </div>
          )}
          <div className="post-content">
            {htmlToReact(_.get(this.props, "pageContext.html"))}
            <form
              name="contactForm"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              id="contact-form"
              className="contact-form"
            >
              <input type="hidden" name="origin" value={origin} />
              <p className="screen-reader-text">
                <label>
                  Don't fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <p className="form-row">
                <label htmlFor="contact-form-name" className="form-label">
                  Name *
                </label>
                <input
                  id="contact-form-name"
                  type="text"
                  name="name"
                  placeholder="Ihr Name..."
                  className="form-input"
                />
              </p>
              <p className="form-row">
                <label htmlFor="contact-form-email" className="form-label">
                  Email *
                </label>
                <input
                  id="contact-form-email"
                  type="email"
                  name="email"
                  placeholder="Ihre Email Adresse..."
                  className="form-input"
                />
              </p>
              <p className="form-row">
                <label htmlFor="contact-form-message" className="form-label">
                  Nachricht *
                </label>
                <textarea
                  id="contact-form-message"
                  name="message"
                  placeholder="Ihre Nachricht..."
                  className="form-textarea"
                  rows="7"
                />
              </p>
              <input type="hidden" name="form-name" value="contactForm" />
              <p className="form-row">
                <button type="submit" className="button">
                  Nachricht senden
                </button>
              </p>
            </form>
          </div>
        </article>
      </Layout>
    );
  }
}
